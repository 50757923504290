<template>
  <div class="mainbox">

    <div style="margin: 10px 0">
      <el-input style="width: 200px" placeholder="请输入名称" suffix-icon="el-icon-search" v-model="name"></el-input>
      <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
    </div>
    <div style="margin: 10px 0">
      <el-upload :action="$baseUrl + '/file/upload'" :show-file-list="false"
                 :on-success="handleFileUploadSuccess" style="display: inline-block">
        <el-button type="primary" class="ml-5">上传文件 <i class="el-icon-top"></i></el-button>
      </el-upload>
      <el-popconfirm
          class="ml-5"
          confirm-button-text='确定'
          cancel-button-text='我再想想'
          icon="el-icon-info"
          icon-color="red"
          title="您确定批量删除这些数据吗？"
          @confirm="delBatch"
      >
        <el-button type="danger" slot="reference">批量删除 <i class="el-icon-remove-outline"></i></el-button>
      </el-popconfirm>

    </div>
    <el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"
              @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" label="ID" width="80"></el-table-column>
      <el-table-column prop="name" label="文件名称"></el-table-column>
      <el-table-column prop="type" label="文件类型"></el-table-column>
      <el-table-column prop="size" label="文件大小(kb)"></el-table-column>
      <el-table-column label="预览">
        <template slot-scope="scope">
          <el-button type="primary" @click="preview(scope.row.url)">预览</el-button>
        </template>
      </el-table-column>
      <el-table-column label="下载">
        <template slot-scope="scope">
          <el-button type="primary" @click="download(scope.row.url)">下载</el-button>
        </template>
      </el-table-column>
      <el-table-column label="启用">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.enable" active-color="#13ce66" inactive-color="#ccc"
                     @change="changeEnable(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" align="center">
        <template slot-scope="scope">
          <el-popconfirm
              class="ml-5"
              confirm-button-text='确定'
              cancel-button-text='我再想想'
              icon="el-icon-info"
              icon-color="red"
              title="您确定删除吗？"
              @confirm="del(scope.row.id)"
          >
            <el-button type="danger" slot="reference">删除 <i class="el-icon-remove-outline"></i></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>

    </el-table>

    <div style="padding: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[2, 5, 10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

        <el-dialog :visible.sync="dialogVisible" :before-close="handleClose">
          <div v-html="myHtml" ></div>
<!--          <video controls autoplay :src="'http://www.yz-awen.cn:9090/file/video/'+url"/>-->
        </el-dialog>

  </div>
</template>

<script>
import {serverIp} from "../../../../public/config";
// import XdocView from './XdocView.js'
export default {
  name: "File",
  data() {
    return {
      serverIp: serverIp,
      tableData: [],
      name: '',
      multipleSelection: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      url: '',
      dialogVisible: false,
      myHtml:  '',
      videoType: ['mp4', 'webm', 'ogg', 'avi', 'mov', 'flv'], // video标签支持的视频类型
      imageType: ['jpg', 'jpeg', 'gif', 'png', 'webp'], // img 标签支持的图像类型
      kkType: ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'wps', 'pdf', 'txt', 'xml', 'java', 'js', 'css',
                'zip', 'rar', 'jar', 'mov', 'rm', 'webm',  'rm', 'mkv', 'mpeg', 'mpg', 'rmvb', 'wmv','swf'],

    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.request.get("/file/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          name: this.name,
        }
      }).then(res => {

        this.tableData = res.data.records
        this.total = res.data.total

      })
    },
    changeEnable(row) {
      this.request.post("/file/update", row).then(res => {
        if (res.code === '200') {
          this.$message.success("操作成功")
        }
      })
    },
    del(id) {
      this.request.delete("/file/" + id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    delBatch() {
      let ids = this.multipleSelection.map(v => v.id)  // [{}, {}, {}] => [1,2,3]
      this.request.post("/file/del/batch", ids).then(res => {
        if (res.code === '200') {
          this.$message.success("批量删除成功")
          this.load()
        } else {
          this.$message.error("批量删除失败")
        }
      })
    },
    reset() {
      this.name = ""
      this.load()
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    handleFileUploadSuccess(res) {
      console.log(res)
      this.$message.success("上传成功")
      this.load()
    },
    download(url) {
      window.open(url)
    },
    preview(url) {
      var ext=url.substring(url.lastIndexOf(".")+1).toLowerCase() // 获取后缀名
      if(this.videoType.includes(ext)){
        this.dialogVisible=true
        // window.open('http://www.yz-awen.cn:9090/file/video/'+url)
        this.url = url
        this.myHtml="<video controls autoplay src=" + this.$baseUrl + "/file/video" + url.substring(url.lastIndexOf('/')) + " style=\"width: 100%; height:100%\"/>"

      }
      if(this.imageType.includes(ext)){
        this.url = url
        this.dialogVisible=true
        this.myHtml="<img src=" + url +" style='max-width: 100%;  max-height: 100%'/>"
        // this.myHtml="<el-image style='width: 100%; height:100%' src=" + url + "></el-image>"
      }
      if(this.kkType.includes(ext)){
        this.url = url
        window.open("http://www.yz-exam.cn:8012/onlinePreview?url=" + encodeURIComponent(window.btoa(url)));
        // window.open('http://127.0.0.1:8012/onlinePreview?url=' + encodeURIComponent(window.btoa((url))))
      }
    },

    handleClose(done){
      this.myHtml =''
      done()
    },
  }
}
</script>

<style scoped>

</style>
